import React, { Component, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'gatsby';
import './Slidein.scss';

function SlideIn(props) {

    const [slideInOpen, setslideInOpen] = useState(false);
    function openSlideIn()  {
        setslideInOpen(true);
        sessionStorage.setItem('slide-status',1);
    }

    function closeSlideIn()  {
        setslideInOpen(false);
    }
        
    useEffect(() => {
        let slideStatus = sessionStorage.getItem('slide-status');
        if(!slideStatus){
            const timeId = setTimeout(() => openSlideIn(), 2000);
            return () => clearTimeout(timeId)
        }
        }, []);

    return (
     <div className={`slidein-container ${slideInOpen ? "is-visible" : ""}`} id="slidein-container"  style={{ visibility: slideInOpen ? "visible" : "hidden" }}>
        <div className='slidein-content'>
       
           <div className="slidein-close">
                <button className="slidein-close" aria-label="Close Slide" id="slidein-close" onClick={() => closeSlideIn()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.246" height="13.246" viewBox="0 0 13.246 13.246">
                        <g id="Group_345" data-name="Group 345" transform="translate(-1101.146 -429.783)">
                        <line id="Line_36" data-name="Line 36" y1="12.539" x2="12.539" transform="translate(1101.5 430.137)" fill="none" stroke="#ffffff" strokeWidth="1"/>
                        <line id="Line_37" data-name="Line 37" x1="12.539" y1="12.539" transform="translate(1101.5 430.137)" fill="none" stroke="#ffffff" strokeWidth="1"/>
                        </g>
                    </svg>
                </button>
            </div>  
            <div>
                <h2 className="">Start the Process</h2>
                <p className="">Secure your spot in our upcoming design schedule and request a call from one of Landscape Designers</p>
                <div>
                    <a className="" href="/contact-us/"> Pencil Me In!</a>
                </div>
            </div>   

        </div>
     </div>
    );
  }


export default SlideIn
