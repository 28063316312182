import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import { SocialLinks } from '../SocialLinks'
import { FaEnvelope, FaPhone, FaMapPin } from 'react-icons/fa';
import './Footer.scss';
import '../../../theme/Footer.scss';
import { decodeEntities } from '../../utils/helpers';
const isClient = typeof window !== 'undefined';
import CookiePolicy from '../CookiePolicy'
import FooterGoogleReviews from '../FooterGoogleReviews';

class Footer extends Component {
  sendEmail(event){
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
      window.location = `mailto:${decodeEntities(email)}`;
  }

  render() {
    const { data, location = {} } = this.props;
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { items: legalNav } = legalNavigation;
    const { items: footerNav } = footerNavigation
    const { footerTagline, navigationInFooter, contactDetailsInFooter, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay
    } = siteSettings.options.locations[0];
    const currentYear = (new Date()).getFullYear();
    const isHome = location.pathname === '/';
    const BirdBrainLink = () => (
      isHome ? (
        <a
          href="https://distl.com.au/"
          target="_blank"
          className="bb"
          rel="noopener noreferrer"
        >
          Distl
        </a>
      ) : <span className="bb">Distl</span>
    );

    return (
      <>
      <footer>
        <div className="footer-main">
          <div className="inner">
            <div className="wrap">

              <div className="logos">
                <Logo />
                {footerTagline && <RenderContent className="tagline" content={footerTagline}/>}
              </div>

              {contactDetailsInFooter && (
                <div className="contact-details">
                  <div className="contact-col">
                    {addressDisplay && (
                      <div className="contact-section">
                        <span className="footer-heading">ADDRESS</span>
                        <a href='https://www.google.com/maps/search/Revell+Landscaping+Perth/@-31.9141764,115.8954891,14z/data=!3m1!4b1?hl=en&entry=ttu'>{addressDisplay}</a>
                      </div>
                    )}
                    {phone && (
                      <div className="contact-section">
                        <span className="footer-heading">PHONE</span>
                        <a
                          href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                          className='control-call'
                          aria-label="Call Now"
                        >
                          {phone}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="contact-col">
                    {email && (
                      <div className="contact-section">
                        <span className="footer-heading">ENQUIRIES</span>
                        <a href="#" onClick={(event) => this.sendEmail(event)}>
                          <span dangerouslySetInnerHTML={{__html: email}} />
                        </a>
                      </div>
                    )}
                    {siteSettings.options.socialLinksFooter && (
                      <div className="contact-section">
                        <span className="footer-heading">SOCIAL</span>
                        <SocialLinks/>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {navigationInFooter && (
                <ul className="footer-nav">
                  {footerNav  && footerNav.map((item, index) => (
                    <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink></li>
                  ))
                  }
                </ul>
              )}
            </div>
            <FooterGoogleReviews />
          </div>
        </div>
        <div className="disclaimers">
          <div className="wrap">
            <span className="credit">
              Website by <BirdBrainLink />
            </span>
            <div className="legals">
              {legalNav && (
                <div className="navigation">
                  <ul>
                    {legalNav.map((item, index) => (
                      <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="copyright">
                <span className="copyright-company">©{currentYear} {wordpressWpSettings.title}</span>
                <span className="copyright-addition">Landscaping Perth</span>
              </div>
            </div>
            
          </div>
        </div>
      </footer>
        {privacyCookiePolicyNotice &&
          <CookiePolicy content={privacyCookiePolicyNoticeText}/>
        }
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        },
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
