import React from 'react';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import '../../sass/global/styles.scss';
import  SlideIn from '../SlideIn';


const Layout = ({ pageClassName, children, location, wordpressUrl, includeCss = false }) => (
    <div id="layout" className={pageClassName}>
      <Helmet title="Home | Koval" />
      <Header location={location} />
      <div className="main">{children}</div>
      <Footer location={location} />
      <SlideIn />
    </div>
)

export default Layout
