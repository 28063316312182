import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ls from 'local-storage'
import GatsbyLink from '../GatsbyLink'
import './Quote.scss'
import { FaShoppingCart } from 'react-icons/fa';

const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

class QuoteIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: ls.get('quote') ? ls.get('quote') : [],
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ quote: ls.get('quote') ? ls.get('quote') : [] }), 1000);
  }

  render() {
    //console.log('this.props:',this.props)
    const { wordpressAcfOptions } = this.props.data
    return (
      <div className="quote-cart">
        <GatsbyLink onClick={enableBodyScroll} to={wordpressAcfOptions.options.quotePage}><FaShoppingCart/>{this.state.quote.length > 0 && <span className="count">{this.state.quote.length}</span>}</GatsbyLink>
      </div>
    )
  }
}

export const QuoteCartIcon = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              quote
              quotePage
              showQuoteButtonInHeader
            }
          }
        }
      `}
      render={data => data.wordpressAcfOptions.options.quote === true && data.wordpressAcfOptions.options.showQuoteButtonInHeader && <QuoteIcon {...props} data={data} />}
    />
  );
}
