import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Facebook } from './Facebook';
import { Twitter } from './Twitter';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

export default class SEO extends Component {
  render() {
    const { title, desc, banner, post, canonical } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressWpSettings {
              siteUrl
              wordpressUrl
              defaultTitle: title
              defaultDescription: description
              defaultBanner: banner
              siteLanguage
              twitter
              shortName
            }
          }
        `}
        render={({
          wordpressWpSettings: {
            defaultTitle,
            siteUrl,
            defaultDescription,
            defaultBanner,
            siteLanguage,
            twitter,
            shortName,
          }
        }) => {
          const seo = {
            title: title || defaultTitle,
            description: desc || defaultDescription,
            image: banner || defaultBanner,
            url: `${siteUrl}`,
            canonical: canonical
          };

          let schemaAreaServed = [
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Revell Landscaping",
                'telephone': '(08) 9379 9686',
                'url': siteUrl,
                'image': 'https://revell.com.au/static/header-logo-9914c5c22399241c04f5f61a2a9d3dc1.svg',
                "address": {
                    '@type': 'PostalAddress',
                    'streetAddress': 'Unit 6/26 River Road',
                    'addressLocality': 'Bayswater',
                    'addressRegion': 'WA',
                    'postalCode': '6053',
                    'addressCountry': 'AU'
                },
                "areaServed": {
                    "@type": "GeoCircle",
                    "geoMidpoint": {
                        "@type": "GeoCoordinates",
                        "latitude": -31.922554433569317,
                        "longitude": 115.92940356718937
                    },
                    "geoRadius": 5000
                },
                // "aggregateRating": {
                //     "@type": "AggregateRating",
                //     "bestRating": 100,
                //     "worstRating": 0,
                //     "ratingValue": 88,
                //     "reviewCount": 122
                // }
            }
        ];

          let schemaOrgJSONLD = [
            {
              '@context': 'https://schema.org',
              '@graph': [
                {
                  '@type': 'Organization',
                  'name': 'Revell Landscaping',
                  'alternateName': 'Revell',
                  'url': siteUrl,
                  'logo': 'https://revell.com.au/static/header-logo-9914c5c22399241c04f5f61a2a9d3dc1.svg',
                  'sameAs': [
                    'https://www.facebook.com/revelllandscaping/',
                    'https://www.pinterest.com.au/revelllandscape/',
                    'https://www.instagram.com/revelllandscaping/',
                    'https://www.houzz.com.au/professionals/landscape-architects-and-designers/revell-landscaping-pfvwau-pf~404193327?'
                  ]
                },
                {
                  '@type': 'WebSite',
                  'name': defaultTitle,
                  'url': siteUrl
                },
                {
                  '@type': 'LocalBusiness',
                  'name': defaultTitle,
                  'image': 'https://revell.com.au/static/header-logo-9914c5c22399241c04f5f61a2a9d3dc1.svg',
                  '@id': siteUrl,
                  'url': siteUrl,
                  'telephone': '(08) 9379 9686',
                  'address': {
                    '@type': 'PostalAddress',
                    'streetAddress': 'Unit 6/26 River Road',
                    'addressLocality': 'Bayswater',
                    'addressRegion': 'WA',
                    'postalCode': '6053',
                    'addressCountry': 'AU'
                  },
                  'geo': {
                    '@type': 'GeoCoordinates',
                    'latitude': -31.9226865,
                    'longitude': 115.9294143
                  }
                }
              ]
            }
          ];

          if (post) {
            schemaOrgJSONLD = [
              {
                '@context': 'http://schema.org',
                '@type': 'BlogPosting',
                '@id': seo.url,
                url: seo.url,
                name: title,
                headline: title,
                image: {
                  '@type': 'ImageObject',
                  url: seo.image,
                },
                description: seo.description,
                datePublished: post.data,
                dateModified: post.data,
                author: {
                  '@type': 'Person',
                  name: post.author || defaultTitle,
                },
                publisher: {
                  '@type': 'Organization',
                  name: post.author || defaultTitle,
                  logo: {
                    '@type': 'ImageObject',
                    url: `${siteUrl}/logos/logo-1200x630.jpg`,
                  },
                },
                isPartOf: siteUrl,
                mainEntityOfPage: {
                  '@type': 'WebSite',
                  '@id': siteUrl,
                },
              },
            ];
          }

                 return (
            <>
              <Helmet title={seo.title}>
                <link rel="canonical" href={seo.canonical} data-src="app" />
                <html lang={siteLanguage} />
                <meta name="description" content={seo.description} />
                <meta name="image" content={seo.image} />
                <meta name="apple-mobile-web-app-title" content={shortName} />
                <meta name="application-name" content={shortName} />
                <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
                <script type="application/ld+json">{JSON.stringify(schemaAreaServed)}</script>
              </Helmet>
              <Facebook
                desc={seo.description}
                image={seo.image}
                title={seo.title}
                type={post ? 'article' : null}
                url={seo.url}
              />
              <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
            </>
          );
        }}
      />
    );
  }
}
