import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './GoogleReviews.scss';

class FooterGoogleReviews extends Component {
  // Gatsby plugin help
  // https://github.com/franklintarter/gatsby-source-google-places
  render() {
    const reviewData = this.props;
 
    return (
      <div className="google-reviews-footer">
        <div className="wrap">
          <p className="footer-rating">
            {reviewData.data.googlePlacesPlace.name} has received <a className="footer-rating-score" href="https://www.google.com/maps/place/Revell+Landscaping/@-31.9227218,115.9272271,17z/data=!4m7!3m6!1s0x2a32a515b7118a4b:0xcd4ad1e9ac8f3d26!8m2!3d-31.9227218!4d115.9294158!9m1!1b1" title="read Google reviews" target="RevellReviews">{reviewData.data.googlePlacesPlace.rating}&nbsp;/&nbsp;5 ★★★★★&nbsp;</a> from {reviewData.data.googlePlacesPlace.user_ratings_total}&nbsp;happy clients on Google!
          </p>
        </div>
      </div>
    )
  };
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        googlePlacesPlace {
          name
          rating
          user_ratings_total
        }
      }
    `}
    render={data => <FooterGoogleReviews data={data} props={props} />}
  />
)