import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { isBrowser } from '../../utils/helpers';
import './Image.scss';

export const getImageUrl = (arrayString) => {
  var srcUrlPass = arrayString;
  if (typeof arrayString === 'string') return arrayString;
  if (srcUrlPass && srcUrlPass.url && srcUrlPass.url !== '') return srcUrlPass.url; //Preview format
  if (srcUrlPass && srcUrlPass.source_url && srcUrlPass.source_url !== '') return srcUrlPass.source_url; //Passing URL
  if (srcUrlPass && srcUrlPass.localFile) return srcUrlPass.localFile.childImageSharp.fluid.src; //Child Sharp Option
  return null;
};

export const Image = (props) => {
  const { src, position = 'absolute', className, alt, loading, fetchpriority } = props;


  if (src === undefined) return null;

  const srcUrlPass = src && getImageUrl(src);

  if (srcUrlPass === null) return null;

  const data = useStaticQuery(graphql`
    query ImageQuery {
      allWordpressWpMedia {
        edges {
          node {
            id
            source_url
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);


  const mediaEdges = data.allWordpressWpMedia.edges;

  let altText = '';
  let fluid = null;

  mediaEdges.forEach(edge => {
    if (edge.node.source_url === srcUrlPass) {
      altText = edge.node.alt_text;
      fluid = edge.node.localFile.childImageSharp.fluid;
    }
  });

  if (srcUrlPass && fluid) {
    // add "normal" html image with eager loading
    if (loading === 'eager') {
      return (
        <div className="gatsby-image gatsby-image-wrapper is-eager">
          <img
            src={fluid.src}
            srcSet={fluid.srcSet}
            sizes={fluid.sizes}
            className={className}
            alt={altText || alt}
            loading="eager"
            style={{
              '--aspect-ratio': fluid.aspectRatio,
            }}
            fetchpriority={fetchpriority}
          />
        </div>
      );
    }

    // add Gatsby image with base64-inline-fluid preview (default)
    return (
      <Img
        className={`gatsby-image ${className}`}
        fluid={fluid}
        style={{
          position: position,
          left: 0,
          top: 0,
          width: '100%',
          minHeight: '100%',
        }}
        alt={altText || alt}
      />
    );
  }

  if (srcUrlPass) {
    return (
      <div
        className="gatsby-image gatsby-image-wrapper"
        style={{
          position: position,
          left: 0,
          top: 0,
          width: '100%',
          minHeight: '100%',
        }}
      >
        <img src={srcUrlPass} className={className} alt={altText || alt} />
      </div>
    );
  }

  return (
    <div
      className={`gatsby-image placeholder ${className}`}
      style={{
        position: position,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    />
  );
};
